<template>
    <div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Schedules</p>
                <p class="text-title mb-0">You do not have permission to view outlet visiting schedules</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0 d-flex justify-space-between">
                    <h2 class="font-weight-regular">Schedules</h2>
                    <v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-btn icon v-bind="attrs" v-on="on" :loading="loading" @click="get"><v-icon>mdi-refresh</v-icon></v-btn></template><span>Refresh</span></v-tooltip>
                </v-col>
                <v-layout align-center justify-center :style="getStyle()" v-if="loading">
                    <v-progress-circular indeterminate :width="3" :size="60" color="primary"></v-progress-circular>
                </v-layout>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center" v-if="!loading">
                    <v-sheet color="white" elevation="0">
                        <v-tabs show-arrows color="primary" v-model="tab" v-if="dates.length > 0">
                            <v-tab :key="index" v-text="getTabText(item)" v-for="(item, index) in dates"></v-tab>
                        </v-tabs>
                        <v-toolbar dense class="mt-2 pa-0 elevation-0" v-if="selectedItems.length > 0">
                            <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click="openAssignDialog">Assign Visitor</v-btn>
                            <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click="openRemoveDialog">Remove Visitor</v-btn>
                        </v-toolbar>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-center" width="5%" v-if="permissions.can_edit"></th>
                                        <th :key="index" class="text-center" v-text="item.text" v-for="(item, index) in headers"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item, index) in items">
                                        <tr :key="sitem.id" v-for="sitem in item.shifts">
                                            <td class="reduced text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                                <v-checkbox hide-details v-model="sitem.selected" @change="handleSelectedItem(sitem)" v-if="isEditable(sitem)"></v-checkbox>
                                            </td>
                                            <td class="reduced text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="sitem.project"></td>
                                            <td class="reduced text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="sitem.campaign"></td>
                                            <td class="reduced text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="sitem.work_date"></td>
                                            <td class="reduced text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="sitem.outlet"></td>
                                            <td class="reduced text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="sitem.start_time"></td>
                                            <td class="reduced text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="sitem.end_time"></td>
                                            <td class="reduced text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="sitem.promoter.name"></td>
                                            <td class="reduced text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="sitem.promoter.mobile_number"></td>
                                            <td class="reduced text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="sitem.area"></td>
                                            <td class="reduced text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.admin.full_name"></td>
                                        </tr>
                                        <tr :key="item.admin.name" v-if="items.length > 1 && index < items.length - 1">
                                            <td colspan="11" class="dense grey darken-1"></td>
                                        </tr>
                                    </template>
                                    <tr v-if="items.length === 0">
                                        <td colspan="11">No shift yet</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-sheet>
                </v-col>
            </v-row>
        </template>
        <assign-dialog ref="assignDialog" :visitors="admins" @updated="handleUpdatedDialog"></assign-dialog>
        <remove-dialog ref="removeDialog" @updated="handleUpdatedDialog"></remove-dialog>
        <message-notifier ref="notifier"></message-notifier>
    </div>
</template>


<script>

import { delay } from '@/utils/helper'
import ShiftAssignDialog from './ShiftAssignDialog.vue'
import ShiftRemoveDialog from './ShiftRemoveDialog.vue'
import MessageNotifier from '../../../shared/MessageNotifier.vue'

export default {
    name: 'DashboardOutletVisiting',
    components: {
        assignDialog: ShiftAssignDialog,
        removeDialog: ShiftRemoveDialog,
        messageNotifier: MessageNotifier,
    },
    data() {
        return {
            loading: true,
            isAllSelected: false,
            tab: null,
            panel: [],
            filters: {
				date: '',
			},
            permissions: {
                can_list: false,
                can_edit: false
            },
            selectedItems: [],
            dates: [],
            items: [],
            admins: [],
            headers: []
        }
    },
    computed: {
        assignDialog() {
            return this.$refs.assignDialog
        },
        removeDialog() {
            return this.$refs.removeDialog
        },
        notifier() {
            return this.$refs.notifier
        }
    },
    mounted: function() {
        delay(this.get, 1500)
    },
    methods: {
        get: function() {
            this.selectedItems = []

            this.$store.dispatch('epanel/dashboard/getOutletVisitingDashboard', this.filters).then((response) => {
                this.dates = response.data.dates
                this.admins = response.data.admins
                this.items = response.data.items
                this.headers = response.data.headers
                this.permissions = response.data.permissions
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        getStyle: function() {
            const height = this.$route.meta.has_bottom_navigation?2*56:56
            const minHeight = (window.innerHeight - height).toString() + "px"
            return {"min-height": minHeight}
        },
        getTabText: function(item) {
            return item.text + "(" + item.total_shifts + ")"
        },
        changeTab: function(tab) {
            this.filters.date = this.dates[tab].value
            this.get()
        },
        handleSelectedItem: function(item){
            var itemIndex = this.selectedItems.indexOf(item.id)
            if(itemIndex > -1){
                this.selectedItems.splice(itemIndex, 1)
            }else{
                this.selectedItems.push(item.id)
            }
            this.isAllSelected = this.selectedItems.length === this.items.length
        },
        handleUpdatedDialog: function(item) {
            this.get()
            this.openNotifier(item.message)
        },
        openAssignDialog: function() {
            this.assignDialog.updateItems(this.selectedItems)
            this.assignDialog.open()
        },
        openRemoveDialog: function() {
            this.removeDialog.updateItems(this.selectedItems)
            this.removeDialog.open()
        },
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        isEditable: function(item){
            return this.permissions.can_edit && item.promoter.name
        }
    },
    watch: {
        tab: function(newValue, oldValue){
            if(Number.isInteger(oldValue)){
                this.changeTab(newValue)
            }
        }
    }
}
</script>


<style scoped>

/deep/ .v-toolbar__content {
    padding: 4px 0px !important;
}

td.dense {
    height: 18px !important;
}

/deep/ .v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
}

</style>