<template>
	<v-dialog persistent max-width="764px" ref="statusDialog" v-model="dialog">
		<v-card>
			<v-card-title class="pl-4 pr-4">
				<span class="text-h5 mb-3">Assign Visitor</span>
			</v-card-title>
			<v-card-text class="pl-4 pr-4">
                <v-form lazy-validation ref="form" v-model="valid">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-alert dense type="warning" class="mb-0">Are you sure that you want to assign visitor on those shifts?</v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-autocomplete label="Visitor" :items="visitors" :rules="rules.visitor" :error-messages="errors.visitor" v-model="object.visitor"></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
			</v-card-text>
			<v-card-actions class="pl-4 pr-4">
				<v-spacer></v-spacer>
				<v-btn text @click="close">Let me think about it</v-btn>
				<v-btn text color="primary" :loading="loading" @click="save">Yes, do it</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>


<script>

import { stringifyJSON } from '@/utils/encodes'

export default {
	name: 'ShiftAssignDialog',
    props: {
        visitors: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            valid: true,
            dialog: false,
            loading: false,
            object: {
                admin: '',
                shifts: []
            },
            rules: {
                visitor: [
                    (value) => !!value || 'Visitor is required'
                ]
            },
            errors: {}
        }
    },
	computed: {
		statusDialog() {
			return this.$refs.statusDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
        close: function() {
            this.dialog = false
            this.loading = false
            this.errors = {}
            this.form.reset()
            this.form.resetValidation()
        },
        updateItems: function(items) {
            this.object.shifts = items
        },
        save: function() {
            if(this.form.validate()){
                const object = { 'shifts': stringifyJSON(this.object.shifts), 'admin': this.object.visitor }
                this.$store.dispatch('epanel/dashboard/updateOutletVisitingShift', object).then((response) => {
                    this.close()
                    this.$emit('updated', { message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:errors
                    this.loading = false
                })
            }
        }
	}
}

</script>